@use '../../styles/mixins' as mixins;

.search-dropdown-block {

	@include mixins.sectionStyles;
	
	.bnpp-container {

		.search-block__content {
			text-align: center;
			max-width: 475px;
			margin: 0 auto;
			
		}

	}

	h2 {
		padding-top: var(--wp--preset--spacing--50);
	}

	/* Base styles */
	.location-dropdown {
		position: relative;
		max-width: var(--wp--style--global--content-size);
		margin: 1rem auto;

		/* Mobile/Desktop visibility */
		@media (min-width: 768px) {
			&.mobile-only {
				display: none;
			}
		}

		@media (max-width: 767px) {
			&.desktop-only {
				display: none;
			}
		}

		/* Custom dropdown styles */
		.location-dropdown__toggle {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: var(--wp--preset--spacing--50);
			background-color: rgb(12 67 53);
			border: none;
			cursor: pointer;
			position: relative;

			label {
				position: absolute;
				transform: scale(0);
				opacity: 0;
			}

			&[aria-expanded="true"] {

				svg {
					transform: rotate(180deg);
				}

			}

			input.location-dropdown__input {
				font-size: var(--wp--preset--font-size--body);
			}

		}

		.location-dropdown__menu {
			display: none;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			background-color: rgb(12 67 53);
			max-height: 300px;
			overflow-y: auto;
			z-index: 9999999;
			padding: 0.5rem;

			&[data-visible="true"] {
				display: block;
			}

			.location-dropdown__option {
				display: flex;
				align-items: center;
				padding: 0.75rem 1rem;
				cursor: pointer;
				border-bottom: solid 1px var(--wp--preset--color--charcoal);

				&:first-child {
					border-top: solid 1px var(--wp--preset--color--charcoal);
				}

				&:hover,
				&[aria-selected="true"] {
					background-color: #0C3023;
					color: #fff;
					border-bottom: solid 1px var(--wp--preset--color--bnpp-green-tint-60);

					&:first-child {
						border-top: solid 1px var(--wp--preset--color--bnpp-green-tint-60);
					}
				}

				.location-dropdown__flag {
					margin-right: 0.75rem;
					object-fit: cover;
					--imageSize: 40px;
					width: var(--imageSize);
					height: var(--imageSize);
					border-radius: 50%;
				}
				
			}

		}
	}

	/* Native select styles */
	.location-dropdown__select {
		width: 100%;
		padding: 0.75rem 1rem;
		border: 1px solid #ccc;
		border-radius: 4px;
		background-color: var(--wp--preset--color--white);
		color: var(--wp--preset--color--bnpp-black);
	}

	&.light {

		:is(input, .location-dropdown__option) {
			color: var(--wp--preset--color--white);
		}

		svg path {
			stroke: var(--wp--preset--color--white);
		}
	}

}